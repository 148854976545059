














import { SMRoot } from "@/store";
import { Component, Vue } from "vue-property-decorator";

type TCol = {
  name: string;
  label: string;
  subs?: {
    label: string;
  }[];
};

@Component
export default class Contents extends Vue {
  protected cols: TCol[] = [
    {
      name: "services",
      label: "Услуги",
      subs: [
        {
          label: "Аналитика",
        },
        {
          label: "Проектирование",
        },
        {
          label: "Разработка",
        },
        {
          label: "Внедрение",
        },
        {
          label: "Эксплуатация",
        },
      ],
    },

    {
      name: "work",
      label: "Как мы работаем",
    },

    {
      name: "modules",
      label: "Модули",
      subs: [
        {
          label: "Базовые",
        },
        {
          label: "Дополнительные",
        },
      ],
    },

    {
      name: "completed-projects",
      label: "Реализованные проекты",
    },

    {
      name: "contact-us",
      label: "Контакты",
    },
  ];

  protected get reorganizedCols() {
    return this.cols.map((col, i) => {
      const colKey = "col:" + i;
      return {
        key: colKey,
        label: col.label,
        action: () => {
          SMRoot.scrollTo(col.name);
        },
        subs:
          col.subs?.map((sub, j) => {
            const subKey = colKey + "/" + "sub:" + j;
            return {
              key: subKey,
              label: sub.label,
              action: () => {
                console.log(sub);
              },
            };
          }) ?? null,
      };
    });
  }
}
